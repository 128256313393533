<template>
    <Skeleton
        v-if="loadingBoard"
        width="40%"
        height="2rem"
        class="mb-6"/>
    <div v-else-if="board" class="board-layout">
        <div class="flex align-items-center align-content-center mb-4">
            <ConfirmPopup/>
            <WidgetModal/>
            <h3 class="mb-0 mr-2">{{ board.title }}</h3>
            <div class="controls flex">
                <Button
                    icon="pi pi-pencil"
                    :loading="loadingEdit"
                    class="p-button-text p-button-success p-button-sm"
                    @click="editBoard"/>
                <Button
                    :loading="loadingDelete"
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger p-button-sm"
                    @click="confirmDeleteBoard"/>
            </div>
            <Button icon="pi pi-unlock"
                    v-tooltip.bottom="'Закрепить виджеты на доске'"
                    :class="['p-button-rounded p-button-text ml-auto p-button-sm', layoutLock ? 'p-button-primary' : 'p-button-secondary']"
                    @click="changeSettings({widgetLayoutLock : !layoutLock})"/>
            <Button icon="pi pi-th-large"
                    v-tooltip.bottom="'Зафиксировать остальные виджеты при перетаскивании'"
                    :class="['p-button-rounded p-button-text p-button-sm', layoutCollision ? 'p-button-primary' : 'p-button-secondary']"
                    @click="changeSettings({widgetLayoutCollision : !layoutCollision})"/>
            <Button
                icon="pi pi-plus"
                :label="`Виджет${ widgets && widgets.length ? '(' + widgets.length + ')' : ''}`"
                class="ml-4 p-button-sm"
                @click="addWidget"/>
        </div>
    </div>
    <WidgetsSkeleton v-if="loadingBoard || widgetsLoading"/>
    <grid-layout
        v-else-if="widgets && widgets.length"
        :layout="widgets"
        :row-height="30"
        :col-num="24"
        :is-draggable="!layoutLock"
        :is-resizable="!layoutLock"
        :vertical-compact="false"
        :prevent-collision="layoutCollision"
        :margin="[10, 10]"
        :max-rows="40"
        :use-css-transforms="true"
    >
        <grid-item v-for="widget in widgets"
                   :x="widget.x"
                   :y="widget.y"
                   :w="widget.w"
                   :h="widget.h"
                   :i="widget.id"
                   :key="widget.id"
                   :min-h="2"
                   :min-w="2"
                   :max-h="20"
                   drag-allow-from=".grabHandler"
                   drag-ignore-from=".grabBlock"
                   @moved="onWidgetMoved"
                   @resized="onWidgetResized"
        >
            <WidgetLayout
                @delete-widget="confirmDeleteWidget"
                @edit-widget="editWidget"
                :isDraggable="!layoutLock"
                :widget="widget"/>
        </grid-item>
    </grid-layout>
</template>

<script>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import BoardService from '../services/BoardService'
import WidgetService from '../services/WidgetService'
import UserService from '../services/UserService'
import { useRoute } from 'vue-router'
import router from '../router'
import { useConfirm } from 'primevue/useconfirm'

export default {
    name: 'Board',
    props: ['boardId'],
    setup () {
        const boardService = new BoardService()
        const widgetService = new WidgetService()
        const userService = new UserService()
        const confirmService = useConfirm()
        const store = useStore()
        const board = computed(() => store.state.board.showBoard)
        const widgets = computed(() => store.getters['widget/getWidgetsLayout'])
        const loadingBoard = computed(() => store.state.board.showBoardLoading)
        const widgetsLoading = computed(() => store.state.widget.getWidgetsLoading)
        const loadingDelete = computed(() => store.state.board.deleteBoardLoading)
        const loadingEdit = computed(() => store.state.board.editBoardLoading)
        const loadingWidget = computed(() => store.state.board.showWidgetLoading)
        const layoutLock = computed(() => store.state.user.getProfile ? !!store.state.user.getProfile.settings.widgetLayoutLock : false)
        const layoutCollision = computed(() => store.state.user.getProfile ? !!store.state.user.getProfile.settings.widgetLayoutCollision : false)
        const route = useRoute()

        const editBoard = async () => {
            await boardService.editBoard(board.value.id).then(() => {
                boardService.modalToggle('edit')
            }).catch(exception => {
                if (exception.response && exception.response.status === 404) {
                    router.push({ name: '404' })
                }
            })
        }
        const addWidget = async () => widgetService.modalToggle('create')
        const confirmDeleteBoard = (event) => {
            confirmService.require({
                target: event.currentTarget,
                message: 'Вы уверены что хотите удалить отчет?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    boardService.deleteBoard(board.value.id).then(() => {
                        widgetService.resetWidgets()
                        router.push({ name: 'addNewBoard' })
                        boardService.getBoards()
                    })
                }
            })
        }
        const confirmDeleteWidget = (event, widgetId) => {
            confirmService.require({
                target: event.currentTarget,
                message: 'Вы уверены что хотите удалить виджет?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    widgetService.deleteWidget(board.value.id, widgetId)
                }
            })
        }
        const editWidget = async (event, widgetId) => {
            await widgetService.showWidget(board.value.id, widgetId).then(() => {
                widgetService.modalToggle('edit')
            }).catch(exception => {
                if (exception.response && exception.response.status === 404) {
                    router.push({ name: '404' })
                }
            })
        }
        const getPageBoard = async (routeState) => {
            if (routeState.params.boardId) {
                widgetService.resetWidgets()
                await boardService.showBoard(
                    routeState.params.boardId
                ).then(() => {
                    widgetService.getWidgets(routeState.params.boardId).then(

                    )
                }).catch(exception => {
                    if (exception.response && exception.response.status === 404) {
                        router.push({ name: '404' })
                    }
                })
            }
        }

        const onWidgetResized = (i, newH, newW, newHPx, newWPx) => {
            widgetService.updatePosition(board.value.id, i, {
                h: newH,
                w: newW
            })
        }

        const onWidgetMoved = (i, newX, newY) => {
            widgetService.updatePosition(board.value.id, i, {
                x: newX,
                y: newY
            })
        }

        watch(route, (state, prevState) => getPageBoard(state))
        onMounted(async () => await getPageBoard(route))

        const changeSettings = (setting) => {
            userService.updateGetProfileSettings(setting)
            userService.updateSettings(setting)
        }

        return {
            board,
            loadingBoard,
            loadingDelete,
            loadingEdit,
            editBoard,
            confirmDeleteBoard,
            addWidget,
            loadingWidget,
            widgets,
            widgetsLoading,
            layoutLock,
            layoutCollision,
            onWidgetMoved,
            onWidgetResized,
            editWidget,
            confirmDeleteWidget,
            changeSettings
        }
    }
}
</script>
<style lang="scss">

.layout-content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.board-layout {
    .controls {
        opacity: 0.10;
        transition: all 250ms;

        &:hover {
            opacity: 1;
        }
    }
}

.vue-grid-item.vue-grid-placeholder {
    background: #FFCC80
}

.vue-grid-item > .vue-resizable-handle {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6IiBvcGFjaXR5PSIuMzAyIiBzdHJva2U9IiNGRkNDODAiIGZpbGw9IiNGRkNDODAiLz48L3N2Zz4=")
}
</style>
